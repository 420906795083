<template>
  <div>
    <b-card-code title="Editer un colis">
      <b-card-text>
        <span>Veuillez saisir les coordonnées de colis</span>
      </b-card-text>
      <!-- form -->
      <validation-observer ref="simpleRules">

        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Nom du client"
                label-for="nom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nom"
                  rules="required"
                >
                  <b-form-input
                    v-model="order.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nom"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Gouvernoart"
                label-for="gouvernoart"
              >
                <validation-provider
                  #default="{ errors }"
                  name="gouvernorat"
                  rules="required"
                >
                  <v-select
                    v-model="order.governorate"
                    :clearable="false"
                    placeholder="Choisissez une gouvernorat"
                    label="name"
                    :options="governorates"
                    @input="getDelegations()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Délégation"
                label-for="delegation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="delegation"
                >
                  <v-select
                    v-model="order.delegation"
                    :clearable="false"
                    placeholder="Choisissez une délégation"
                    label="name"
                    :options="delegations"
                    @input="getLocalities()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Localité"
                label-for="localité"
              >
                <validation-provider
                  #default="{ errors }"
                  name="localité"
                >
                  <v-select
                    v-model="order.locality"
                    :clearable="false"
                    placeholder="Choisissez une localité"
                    label="name"
                    :options="localities"
                    :disabled="!order.governorate || !order.delegation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Adresse complètes"
                label-for="adresse"
              >
                <validation-provider
                  #default="{ errors }"
                  name="adresse"
                  rules="required"
                >
                  <b-form-input
                    v-model="order.address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Adresse complètes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Téléphone"
                label-for="Téléphone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Téléphone"
                  rules="required|integer|length:8"
                >
                  <b-form-input
                    v-model="order.phone1"
                    type="tel"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Téléphone "
                    :formatter="formatPhone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Téléphone 2"
                label-for="Téléphone2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone2"
                  rules="integer|length:8"
                >
                  <b-form-input
                    v-model="order.phone2"
                    type="tel"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Téléphone 2 "
                    :formatter="formatPhone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- <b-col
              md="4"
            >
              <b-form-group
                label="Montant TTC"
                label-for="montant ttc"
              >
                <validation-provider
                  #default="{ errors }"
                  name="montant ttc"
                  rules="required"
                >
                  <b-form-input
                    v-model="order.price_ttc"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Montant TTC"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col
              md="4"
            >
              <b-form-group
                label="Prix de livraison"
                label-for="Prix de livraison"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Prix de livraison"
                  rules="required"
                >
                  <b-form-input
                    v-model="order.price_delivery"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Prix de livraison"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="role === 'admin'"
              md="4"
            >
              <b-form-group
                label="Montant TTC"
                label-for="montant ttc"
              >
                <validation-provider
                  #default="{ errors }"
                  name="montant ttc"
                  rules="required"
                >
                  <b-form-input
                    v-model="order.price_ttc"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Montant TTC"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="user.weight_price === true"
              md="4"
            >
              <b-form-group
                label="Poids volumétrique de colis"
                label-for="Poids volumétrique de colis"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Poids de colis"
                  rules="required"
                >
                  <v-select
                    v-model="order.weight"
                    :clearable="false"
                    placeholder="Poids volumétrique de colis"
                    label="weight"
                    :options="user.weights"
                    @input="fetch_price"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group
                label="Mode de paiement"
                label-for="type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  rules="required"
                >
                  <v-select
                    v-model="order.payment_method"
                    :clearable="false"
                    placeholder="Mode de paiement"
                    label="value"
                    :options="types"
                    :reduce="type => type.key"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="demo-inline-spacing"
            >
              <b-form-checkbox
                v-model="order.is_an_exchange"
                name="check-button"
                switch
                inline
              >
                Échange
              </b-form-checkbox>
              <b-form-checkbox
                v-model="order.is_fragile"
                name="check-button"
                switch
                inline
              >
                Fragile
              </b-form-checkbox>
              <b-form-checkbox
                v-model="order.is_discharge"
                name="check-button"
                switch
                inline
              >
                Décharge
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>

        <b-card-code title="Produits">
          <b-card-text>
            <span>Veuillez saisir les coordonnées des produits </span>
          </b-card-text>
          <!-- form -->
          <validation-observer ref="simpleRules">
            <div>
              <div>
                <b-form
                  ref="form"
                  :style="{ height: trHeight }"
                  class="repeater-form"
                  @submit.prevent="repeateAgain"
                >
                  <!-- Row Loop -->
                  <b-row
                    v-for="(item, index) in items"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                  >

                    <!-- produit -->
                    <b-col md="6">
                      <b-form-group
                        label="Produit"
                        label-for="produit"
                      >
                        <b-form-input
                          v-model="item.product"
                          placeholder="Produit"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Quantity -->
                    <b-col md="3">
                      <b-form-group
                        label="Quantity"
                        label-for="quantity"
                      >
                        <b-form-input
                          id="quantity"
                          v-model="item.quantity"
                          type="number"
                          placeholder="Quantity"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="6"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>Supprimer</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </b-form>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-2"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Nouveau produit</span>
              </b-button>

              <b-row>

                <b-col md="12">
                  <b-form-group
                    label="Commentaire"
                    label-for="Commentaire"
                  >
                    <b-form-textarea
                      id="textarea-rows"
                      v-model="order.note"
                      placeholder="Commentaire"
                      rows="1"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-3"
                    :class="{ 'disabled-cursor': isLoading || !isOrderValid }"
                    :disabled="isLoading || !isOrderValid"
                    @click.prevent="updateOrder"
                  >
                    Modifier
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    type="reset"
                    class="mt-3"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  >
                    Réinitialiser
                  </b-button>
                </b-col>
              </b-row>

            </div>
          </validation-observer>
        </b-card-code>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* import vSelect from 'vue-select' */
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BCardText /*  */,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import storeAuth from '@/store/store'

export default {
  name: 'EditOrder',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
        {
          type: '',
          product: '',
          quantity: 1,
        },
      ],
      itemsOptions: [],
      nextTodoId: 1,
      type: '',
      types: [
        {
          key: 0,
          value: 'Espèce',
        },
        {
          key: 1,
          value: 'par chèque',
        },
      ],
      governorates: [],
      governorate: {},
      delegations: [],
      localities: [],
      order: {
      },
      required,
      user: {
        weight_price: false,
      },
      role: null,
      isLoading: false,
    }
  },
  computed: {
    isOrderValid() {
      return (
        this.order.name !== ''
        && this.order.phone1 !== ''
        && this.order.price_ttc !== ''
        && this.order.governorate !== ''
      )
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.role = storeAuth.state.user.role
    this.items.shift()
    this.getOrder()
    this.getGovernorates()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async fetch_price() {
      const result = this.user.prices.find(element => element.weight.id === this.order.weight.id)
      this.order.price_delivery = result.delivery_price
      this.order.price_return = result.return_price
    },
    async getOrder() {
      this.isLoading = true
      const { data } = await axios.get(`/api/orders/edit/${this.$route.params.id}`)
      this.order = data
      this.user = this.order.supplier
      this.governorate = this.order.governorate

      this.getDelegations()
      if (this.order != null) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.order.products.length; i++) {
          const qte = this.order.products[i].quantity
          const { product } = this.order.products[i]
          this.repeate(qte, product)
        }
      }
      this.isLoading = false
    },
    async getGovernorates() {
      const { data } = await axios.get('/api/governorates/')
      this.governorates = data
    },
    async getDelegations() {
      const { data } = await axios.get('/api/delegations/', { params: { governorate: this.order.governorate.id } })
      this.delegations = data
      this.getLocalities()
    },
    async getLocalities() {
      const { data } = await axios.get('/api/localities/', { params: { delegation: this.order.delegation.id } })
      this.localities = data
    },
    repeate(qte, product) {
      this.items.push({
        product,
        quantity: qte,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    repeateAgain() {
      this.items.push({
        product: '',
        quantity: 1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    async updateOrder() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          let delegation = null
          let localityId = null
          let weight = null
          if (this.order.delegation != null) {
            delegation = this.order.delegation.id
          }
          if (this.order.locality != null) {
            localityId = this.order.locality.id
          }
          if (this.order.weight != null) {
            weight = this.order.weight.id
          }
          await axios
            .put(
              `/api/orders/update/${this.$route.params.id}/`, {
                name: this.order.name,
                address: this.order.address,
                phone1: this.order.phone1,
                phone2: this.order.phone2,
                note: this.order.note,
                price_ttc: this.order.price_ttc,
                price_delivery: this.order.price_delivery,
                price_return: this.order.price_return,
                type: this.order.type,
                governorate: this.order.governorate.id,
                delegation,
                locality: localityId,
                products: this.items,
                is_an_exchange: this.order.is_an_exchange,
                is_fragile: this.order.is_fragile,
                is_discharge: this.order.is_discharge,
                payment_method: this.order.payment_method,
                weight,
              },
            )
            .then(() => {
              this.$router.push({ name: 'order-show', params: { id: this.$route.params.id } })
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Colis modifié avec succés')
              }, 1000)
            })
            .catch(err => {
              setTimeout(() => {
                this.showToast('danger', 'top-center', err.toString())
              }, 1000)
            })
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatPhone(e) {
      return String(e).substring(0, 8)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.disabled-cursor {
  cursor: not-allowed;
  opacity: 0.8;
}
</style>
